/* Make clicks pass-through */
#nprogress {
  pointer-events: none;

  .bar {
    background: $primary-color;

    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 4px;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $primary-color, 0 0 5px $primary-color;
    opacity: 1.0;

    transform: rotate(3deg) translate(0px, -4px);
  }

  .spinner {
    display: block;
    position: fixed;
    z-index: 9999;
    bottom: 15px;
    right: 15px;
  }

  .spinner-icon {
    width: 24px;
    height: 24px;
    box-sizing: border-box;

    border: solid 3px transparent;
    border-top-color: $primary-color;
    border-left-color: $primary-color;
    border-radius: 50%;

    animation: nprogress-spinner 400ms linear infinite;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;

  #nprogress .spinner,
  #nprogress .bar {
    position: absolute;
  }
}

@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

