// SweetAlert2
// github.com/limonte/sweetalert2

@import 'colors';

body {
  &.swal2-shown {
    overflow-y: hidden;
  }

  &.swal2-iosfix {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.swal2-container {
  // centering
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;

  // backdrop
  background-color: transparent;

  z-index: 1060;

  &.swal2-fade {
    transition: background-color .1s;
  }

  &.swal2-shown {
    background-color: $swal2-overlay;
  }
}

.swal2-modal {
  background-color: $swal2-white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  position: relative;
  max-width: 100%;

  &:focus {
    outline: none;
  }

  &.swal2-loading {
    overflow-y: hidden;
  }

  .swal2-title {
    color: lighten($swal2-black, 35);
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 0 0 .4em;
    padding: 0;
    display: block;
    word-wrap: break-word;
  }

  .swal2-buttonswrapper {
    margin-top: 15px;

    &:not(.swal2-loading) {
      .swal2-styled {
        &[disabled] {
          opacity: .4;
          cursor: no-drop;
        }
      }
    }

    &.swal2-loading {
      .swal2-styled {
        &.swal2-confirm {
          box-sizing: border-box;
          border: 4px solid transparent;
          border-color: transparent;
          width: 40px;
          height: 40px;
          padding: 0;
          margin: 7.5px;
          vertical-align: top;
          background-color: transparent !important;
          color: transparent;
          cursor: default;
          border-radius: 100%;
          animation: rotate-loading 1.5s linear 0s infinite normal;
          user-select: none;
        }

        &.swal2-cancel {
          margin-left: 30px;
          margin-right: 30px;
        }
      }

      :not(.swal2-styled) {
        &.swal2-confirm {
          &::after {
            display: inline-block;
            content: '';
            margin-left: 5px 0 15px;
            vertical-align: -1px;
            height: 15px;
            width: 15px;
            border: 3px solid lighten($swal2-black, 60);
            box-shadow: 1px 1px 1px $swal2-white;
            border-right-color: transparent;
            border-radius: 50%;
            animation: rotate-loading 1.5s linear 0s infinite normal;
          }
        }
      }
    }
  }

  .swal2-styled {
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    color: $swal2-white;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    margin: 15px 5px 0;
    padding: 10px 32px;
  }

  .swal2-image {
    margin: 20px auto;
    max-width: 100%;
  }

  .swal2-close {
    background: $swal2-transparent;
    border: 0;
    margin: 0;
    padding: 0;
    width: 38px;
    height: 40px;
    font-size: 36px;
    line-height: 40px;
    font-family: serif;
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: $swal2-close-button;
    transition: color .1s ease;

    &:hover {
      color: $swal2-close-button-hover;
    }
  }

  > .swal2-input,
  > .swal2-file,
  > .swal2-textarea,
  > .swal2-select,
  > .swal2-radio,
  > .swal2-checkbox {
    display: none;
  }

  .swal2-content {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    position: relative;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: lighten($swal2-black, 33);
    word-wrap: break-word;
  }

  .swal2-input,
  .swal2-file,
  .swal2-textarea,
  .swal2-select,
  .swal2-radio,
  .swal2-checkbox {
    margin: 20px auto;
  }

  .swal2-input,
  .swal2-file,
  .swal2-textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    border-radius: 3px;
    border: 1px solid $swal2-input-border;
    box-shadow: inset 0 1px 1px $swal2-input-box-shadow;
    transition: border-color box-shadow .3s;

    &.swal2-inputerror {
      border-color: $swal2-error !important;
      box-shadow: 0 0 2px $swal2-error !important;
    }

    &:focus {
      outline: none;
      border: 1px solid $swal2-input-border-focus;
      box-shadow: 0 0 3px $swal2-input-box-shadow-focus;

      &::placeholder {
        transition: opacity .3s .03s ease;
        opacity: .8;
      }
    }

    &::placeholder {
      color: lighten($swal2-black, 90);
    }
  }

  .swal2-range {
    input {
      float: left;
      width: 80%;
    }

    output {
      float: right;
      width: 20%;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    input,
    output {
      height: 43px;
      line-height: 43px;
      vertical-align: middle;
      margin: 20px auto;
      padding: 0;
    }
  }

  .swal2-input {
    height: 43px;
    padding: 0 12px;

    &[type='number'] {
      max-width: 150px;
    }
  }

  .swal2-file {
    font-size: 20px;
  }

  .swal2-textarea {
    height: 108px;
    padding: 12px;
  }

  .swal2-select {
    color: lighten($swal2-black, 33);
    font-size: inherit;
    padding: 5px 10px;
    min-width: 40%;
    max-width: 100%;
  }

  .swal2-radio {
    border: 0;

    label {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    input,
    span {
      vertical-align: middle;
    }

    input {
      margin: 0 3px 0 0;
    }
  }

  .swal2-checkbox {
    color: lighten($swal2-black, 33);

    input,
    span {
      vertical-align: middle;
    }
  }

  .swal2-validationerror {
    background-color: lighten($swal2-black, 94);
    margin: 0 -20px;
    overflow: hidden;
    padding: 10px;
    color: lighten($swal2-black, 50);
    font-size: 16px;
    font-weight: 300;
    display: none;

    &::before {
      content: '!';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $swal2-validationerror-background;
      color: $swal2-validationerror-color;
      line-height: 24px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

@supports (-ms-accelerator: true) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

.swal2-icon {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;
  user-select: none;

  &.swal2-error {
    border-color: $swal2-error;

    .swal2-x-mark {
      position: relative;
      display: block;
    }

    [class^='swal2-x-mark-line'] {
      position: absolute;
      height: 5px;
      width: 47px;
      background-color: $swal2-error;
      display: block;
      top: 37px;
      border-radius: 2px;

      &[class$='left'] {
        transform: rotate(45deg);
        left: 17px;
      }

      &[class$='right'] {
        transform: rotate(-45deg);
        right: 16px;
      }
    }
  }

  &.swal2-warning {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $swal2-warning;
    border-color: lighten($swal2-warning, 7);
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.swal2-info {
    font-family: 'Open Sans', sans-serif;
    color: $swal2-info;
    border-color: lighten($swal2-info, 20);
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.swal2-question {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $swal2-question;
    border-color: lighten($swal2-question, 20);
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.swal2-success {
    border-color: $swal2-success;

    [class^='swal2-success-circular-line'] { // Emulate moving circular line
      border-radius: 50%;
      position: absolute;
      width: 60px;
      height: 120px;
      transform: rotate(45deg);

      &[class$='left'] {
        border-radius: 120px 0 0 120px;
        top: -7px;
        left: -33px;

        transform: rotate(-45deg);
        transform-origin: 60px 60px;
      }

      &[class$='right'] {
        border-radius: 0 120px 120px 0;
        top: -11px;
        left: 30px;

        transform: rotate(-45deg);
        transform-origin: 0 60px;
      }
    }

    .swal2-success-ring { // Ring
      width: 80px;
      height: 80px;
      border: 4px solid $swal2-success-border;
      border-radius: 50%;
      box-sizing: content-box;

      position: absolute;
      left: -4px;
      top: -4px;
      z-index: 2;
    }

    .swal2-success-fix { // Hide corners left from animation
      width: 7px;
      height: 90px;

      position: absolute;
      left: 28px;
      top: 8px;
      z-index: 1;

      transform: rotate(-45deg);
    }

    [class^='swal2-success-line'] {
      height: 5px;
      background-color: $swal2-success;
      display: block;
      border-radius: 2px;

      position: absolute;
      z-index: 2;

      &[class$='tip'] {
        width: 25px;

        left: 14px;
        top: 46px;

        transform: rotate(45deg);
      }

      &[class$='long'] {
        width: 47px;

        right: 8px;
        top: 38px;

        transform: rotate(-45deg);
      }
    }
  }
}

.swal2-progresssteps {
  $lightblue: #add8e6;
  $blue: #3085d6;

  font-weight: 600;
  margin: 0 0 20px;
  padding: 0;

  li {
    display: inline-block;
    position: relative;
  }

  .swal2-progresscircle {
    background: $blue;
    border-radius: 2em;
    color: $swal2-white;
    height: 2em;
    line-height: 2em;
    text-align: center;
    width: 2em;
    z-index: 20;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.swal2-activeprogressstep {
      background: $blue;

      ~ .swal2-progresscircle {
        background: $lightblue;
      }

      ~ .swal2-progressline {
        background: $lightblue;
      }
    }
  }

  .swal2-progressline {
    background: $blue;
    height: .4em;
    margin: 0 -1px;
    z-index: 10;
  }
}


// github.com/limonte/sweetalert2/issues/268
[class^='swal2'] {
  -webkit-tap-highlight-color: $swal2-transparent;
}

// Animations

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}


// Modal animation

@include keyframes(showSweetAlert) {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@include keyframes(hideSweetAlert) {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(.5);
    opacity: 0;
  }
}

.swal2-show {
  @include animation('showSweetAlert 0.3s');

  &.swal2-noanimation {
    @include animation('none');
  }
}

.swal2-hide {
  @include animation('hideSweetAlert 0.15s forwards');

  &.swal2-noanimation {
    @include animation('none');
  }
}



// Success icon animation

@include keyframes(animate-success-tip) {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@include keyframes(animate-success-long) {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@include keyframes(rotatePlaceholder) {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

.swal2-animate-success-line-tip {
  @include animation('animate-success-tip 0.75s');
}

.swal2-animate-success-line-long {
  @include animation('animate-success-long 0.75s');
}

.swal2-success {
  &.swal2-animate-success-icon {
    .swal2-success-circular-line-right {
      @include animation('rotatePlaceholder 4.25s ease-in');
    }
  }
}


// Error icon animation

@include keyframes(animate-error-icon) {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.swal2-animate-error-icon {
  @include animation('animate-error-icon 0.5s');
}

@include keyframes(animate-x-mark) {
  0% {
    transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    transform: scale(.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.swal2-animate-x-mark {
  @include animation('animate-x-mark 0.5s');
}

@include keyframes(rotate-loading) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
