$product-dropdown-arrow-size: 5px;

.products-container{

  .heading{
    width: 100%;
    padding: 10px 0;
  }
}
[data-select-all]{
  align-self: flex-end;
}
.collapse{
  padding: 0;
}
.product{
  &-bar{
    width: 100%;
    color: $dark-gray;
    display: flex;
    padding: 5px;
    flex-basis: 100%;
  }
  &-name{
    padding: 0 5px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    background: $primary-color;
    color: $white;
    transition: all 0.5s;
    white-space: nowrap;
    overflow: hidden;
    justify-content: flex-start;
    //flex-basis: 90%;
    flex-grow: 0;
    flex-shrink: 4;

    span{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover{
      background: scale-color($primary-color, $lightness: -20%);
      color: $white;
    }

    &:focus, &:active{
      color: $white;
    }
  }
  &-checkbox.checkbox{
    label{
      padding-left: 5px;
    }

  }
  &-id{
    word-break: keep-all;
    justify-content: flex-start;
    display: flex;
    margin: 0 5px;
    flex-grow: 2;
    flex-shrink: 0;
    margin-right: 10px;

    span{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 5px;
      background: scale-color($white, $lightness: -5%);
      color: $light-gray;
      font-size: 0.7em;
    }
  }
  &-expand{
    font-size: 9px;
    white-space: nowrap;
    display: flex;
    padding-right: 5px;
    align-items: center;
    justify-content: center;
    color: $primary-color;

    .show-details{

    }
    .hide-details{
      display: none;
    }
  }
  &-arrow{
    flex-basis: 30px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow{
      width: 0;
      height: 0;
      border-top: $product-dropdown-arrow-size solid transparent;
      border-bottom: $product-dropdown-arrow-size solid transparent;

      border-right: $product-dropdown-arrow-size solid $medium-gray;
      transition: all 0.5s;
    }
  }
  &-dropdown{
    max-height: 0;
    transition: all 0.5s;
    overflow: hidden;
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .section{
      flex-basis: 100%;
      font-size: 1em;
      color: $dark-gray;
      line-height: 1.2rem;

      span{
        font-weight: 700;
      }
    }
  }

  &-container{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $medium-gray;
    margin-bottom: 10px;
    page-break-inside: avoid;

    *{
      user-select: none;
    }

    &:hover{
      cursor: pointer;
    }

    &.no-products{

      .product-bar{
        background: scale-color($white, $lightness: -10%);
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px;
        user-select: none;
      }

      &:hover{
        cursor: default;
      }
    }

    &.active{

      .product-bar{
         flex-basis: 100%;
       }
      .product-arrow{

        .arrow{
          transform: rotate(-90deg);
        }
      }
      .product-dropdown{
        max-height: 1000px;
        flex-basis: 100%;
        padding: 5px 5px;
      }
      .show-details{
        display: none;
      }
      .hide-details{
        display: block;
      }
    }

    @media only print {

      .product-bar{
        display: none;
      }
      .product-dropdown{
        max-height: 1000px;
        flex-basis: 100%;
        padding: 5px 5px;
        overflow: auto;
      }

    }
  }

}