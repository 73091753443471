.orders-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $bvpl $bhpl;
  flex-wrap: wrap;
}

.order{

  &-items{
    position: absolute;
    bottom: $bvpl/2;
    right: $bhpl/2;
    font-size: 10px;
    color: $dark-gray;
    transition: all 0.5s;
  }
  &-title{
    font-size: 1.35em;
    margin-bottom: 5px;
    position: relative;
    transition: all 0.5s;
    color: $primary-color;
    font-weight: bold;

    hr{
      margin: 0;
      margin-top: 2px;
      border-color: $medium-gray;
    }
    span{
      font-size: 0.5em;
      position: absolute;
      bottom: 4px;
      right: 2px;
    }
  }
  &-shipped{
    font-size: 0.8em;
    color: $dark-gray;
    margin-bottom: 20px;
    transition: all 0.5s;
  }

  &-container {
    $mg-left: $bhpl;
    padding: $bvpl $bhpl;
    background: scale-color($white, $lightness: -5%);
    border: 1px solid scale-color($medium-gray, $lightness: 25%);
    margin-bottom: $mg-left;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    display: block;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;


    @include breakpoint(medium up){
      margin-left: $mg-left;
      flex-basis: 40%;

      &:nth-of-type(2n+1){
        margin-left: 0;
      }
    }

    @include breakpoint(large up){
      flex-basis: 30%;

      &:nth-of-type(2n+1){
        margin-left: $mg-left;
      }
      &:nth-of-type(3n+1){
        margin-left: 0;
      }
    }

    @include breakpoint(xxlarge up){
      flex-basis: 20%;

      &:nth-of-type(3n+1){
        margin-left: $mg-left;
      }
      &:nth-of-type(4n+1){
        margin-left: 0;
      }
    }

    &.missing-order{
      display: flex;
      align-items: center;
      justify-content: center;

      .order-title{
        color: $primary-color;
        font-weight: 700;
        padding: 0;
        margin: 0;
      }
    }

    &:hover{
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
      background: scale-color($white, $lightness: -2%);

    }
  }

}