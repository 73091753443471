#content-container{
  position: absolute;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  overflow-scrolling: touch;
  transition: all 0.6s;

  @media only print{
    top: 0;
    position: static;
    padding-top: 30px;
  }

  .content-container-wrapper{
    min-height: 100%;
    padding-bottom: 70px;
  }
  #footer{

  }
  #footer{
    padding: $global-padding;
    border-top: 1px solid $medium-gray;
    width: 100%;
    margin-top: -60px;
  }
}

.modal-active {
  #content-container, #nav-container{
    filter: blur(2px);
  }
  #content-container{
    overflow: hidden;
  }

}
.checkbox{
  position: relative;

  input{
    display: none;
  }
  label{
    padding-left: 25px;

    &::before{
      position: absolute;
      content: '';
      font-family: FontAwesome;
      left: 0;
      top: 0px;
      height: 25px;
      width: 25px;
      background: scale-color($white, $lightness: -5%);
      border: 1px solid $dark-gray;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0em;
    }
  }
  input:checked + label{

    &::before{
      background: $primary-color;
      content: '\f00c';
      color: $white;
      padding: 0;
      font-size: 1em;
      border-color: $primary-color;
    }
  }
}
.pad{

  &-top{
    padding-top: 12px;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px $white inset !important;
}
.highlight-text{
  color: $primary-color;
  background: scale-color($white, $lightness: -5%);
  border-radius: 5px;
  padding: 0 5px;

  &.no-color{
    color: $body-font-color;
  }
}
a.label{

  &:hover{
    cursor: pointer;
    color: $white;
    background: scale-color($primary-color, $lightness: 10%);
  }

  &:focus, &:active{
    color: $white;
  }

  &.secondary:hover{
    background: scale-color($secondary-color, $lightness: 10%);
  }
}
.no-border{
  border: none !important;
}

.swal2-confirm.swal2-styled{
  background: $primary-color !important;
}
[disabled]{
  opacity: 0.25;
}
.input-group{
  input{
    margin-bottom: 0;
  }
}

.centerize{
  display: flex !important;
  align-items: center !important;
  width: 100%;
  margin: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  margin-bottom: $global-margin;
}

.margin-bottom{
  margin-bottom: $global-margin;
}

.show-for-print-only{
  display: none;
}
@media only print {
  .hide-for-print{
    display: none;
  }
  .show-for-print-only{
    display: flex;
  }
  .print-12.columns{
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.row{
  overflow-x: hidden;
}
#print-header{
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  text-align: right;
  height: 20px;
}