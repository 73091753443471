$background-scale-factor: 7.5%;
$border-color: scale-color($medium-gray, $lightness: 20%);
$nav-border: 1px solid $border-color;

.nav-container{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 45px;
  width: 100%;
  background: $white;
  border-bottom: 1px solid $medium-gray;
  display: flex;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);

  @media only print {
    display: none !important;
  }

  .search-container{
    flex-basis: 99%;
    flex-shrink: 3;
    flex-grow: 0;

    .hidden-form{
      padding:0;
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
    }
    .search-input-container{
      flex-basis: 99%;
      flex-shrink: 1;
      flex-grow: 0;

      @include breakpoint(medium up){
        flex-basis: 30%;
        max-width: 450px;
        min-width: 300px;
      }

      input{
        background: none;
        border: none;
        padding: $bvpl $bhpl;
        height: 45px;
        width: 100%;
        padding-right: 0;
        transition: all 0.5s;
        box-shadow: none;
        margin: 0;
        line-height: 12px;

        &:focus{
          outline: none;
        }
      }
    }
    button{
      border:none;
      background: none;
      color: $medium-gray;
      padding: $bvpl 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      transition: all 0.5s;
      outline: none;
      box-shadow: none;
      max-height: 45px;

      @include breakpoint(medium up){
        border-right: 1px solid $medium-gray;
        font-size: 20px;
      }

      &:hover{
        text-decoration: none;
        color: $dark-gray;
        cursor: pointer;
        background: scale-color($white, $lightness: -$background-scale-factor);
      }

      &[type="submit"]{
        border-right: 1px solid $medium-gray;

        i{
          color: $primary-color;
          transition: all 0.5s;
        }

        &:hover{
          color: scale-color($primary-color, $lightness: -$background-scale-factor);
          background: $white;

          i{
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .links-container{
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;

    .link{
      display: flex;
      align-items: center;
      justify-content: center;
      color:$medium-gray;
      border-right: $nav-border;
      font-size: 18px;
      padding: 15px;
      transition: all 0.5s;

      &:hover{
        text-decoration: none;
        color: $dark-gray;
        background: scale-color($white, $lightness: -$background-scale-factor);
      }

      @include breakpoint(medium up){
        font-size: 14px;
        padding: 20px;
      }
    }
    &-end{
      .link{
        border-left: $nav-border;
        border-right: none;
      }
    }
  }
}
