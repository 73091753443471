
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "settings";

@import "node_modules/sweetalert2/src/sweetalert2";
@import "node_modules/foundation-sites/scss/foundation";

@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-callout;
@include foundation-label;
@include foundation-table;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@import "components/components";
@import "global";

*{

  &:focus{
    outline: none;
    text-decoration: none;
  }
  &:active{
    text-decoration: none;
  }
}