.modal{
  position: fixed;
  left: 0;
  z-index: 101;
  height: 100%;
  width: 100%;
  background: rgba(10, 10, 10, 0);
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: scale(0);
  transition: scale 0.01s, background 0.25s;
  transition-delay: 0s;

  &.modal-hidden{
    display: none;
  }

  &-content{
    transform: scale(0);
    transition: all 0.5s;
    transition-delay: 0.25s;
    background: $white;
    border-color: $medium-gray;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 3px 4px rgba(10, 10, 10, 0.4);
    position: relative;

    @media only screen and (max-height: 880px){
      height: 100%;
      overflow: auto;
      overflow-scrolling: touch;
    }
  }
  &-heading{
    font-size: 1.7em;
    color: $dark-gray;
  }
  &-message{
    color: $dark-gray;
  }
  &-form{
    margin-top: 10px;

    textarea{
      height: 150px;
      resize: none;
    }
  }
  &-controls{

    .button:last-of-type{
      margin-right: 0;
    }
  }
  [data-id="order-number"]{
    color: $primary-color;
    background: scale-color($white, $lightness: -5%);
    border-radius: 5px;
    padding: 0 5px;
  }
  &-close-button{
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 1.5em;
  }

  &.active{
    background: rgba(0, 0, 0, 0.4);
    transform: scale(1);
    transition-delay: 0s;

    .modal-content{
      transform: scale(1);
      transition-delay: 0.25s;
    }
  }
}